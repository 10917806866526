import backend from "./backend";

class auth {
    constructor() {
        this.isAuthenticated = JSON.parse(window.localStorage.getItem("auth")) || {isAuthenticated:false,registered:false,platform:false,};
        if(JSON.parse(localStorage.getItem("auth"))=== null) {
            window.localStorage.setItem("auth", JSON.stringify(this.isAuthenticated));
        }
    }

  authenticate(user,cb) {
      if(!this.isAuthenticated.isAuthenticated) {
          window.localStorage.setItem("auth", JSON.stringify({isAuthenticated:true, ...user}))
        return JSON.parse(this.isAuthenticated.isAuthenticated)
      }  
    setTimeout(cb, 100); 
  }
  get status() {
      return this.isAuthenticated.isAuthenticated
  }
  get auth() {
    return this.isAuthenticated
}
slack(status) {
  backend.updateData("registered", status).then(res => {}).catch(err=> auth.signout(()=> window.location.reload(true)))
}

  signout(cb) {
    this.isAuthenticated = {isAuthenticated:false, level:"Beginner",skill:"Front-End"};
    window.localStorage.setItem("auth",JSON.stringify(this.isAuthenticated));
    setTimeout(cb, 100);
  }
}

export default new auth();