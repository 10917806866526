import React, {Component} from 'react'
import './steps.css'
import Button from '../Form/Button/Button';
import { connect } from '../../utils/context';
import backend from '../../utils/backend';
import auth from '../../utils/auth';


export class SignupWithSlack extends Component {
  static contextType = connect
  state = {
    taged: false,
    data: [],
    message:"",
    text: "Join us",
    err:null,
    isloading: false,
  }
  handleSignupManually = () => {
    auth.slack(false)

  }
  handleSignup = () => {
    this.setState({isloading: true})
    backend.signupForSlack().then(res => {
      this.setState({
        message: res.data.message,
        text:"joined",
        isloading:false
      })
      auth.slack(true)
    }).catch(err => {
      auth.slack(false)
      this.setState(
        {
          message: "Please Try registering manualy!",
          err: err.message
        }
        )
      })
    }
    
    render() {
    return (
      <div>
        <p className="gray_color">The CodeDoor network communicates via Slack. Use this invite to join the CodeDoor Slack Community. </p>
        {
          this.context.user.registered ? (<Button text={"Already Registered!"} />)
          : this.state.err ? (<Button text={"Open Page "}  onClick={this.handleSignupManually} link={"https://code-door.herokuapp.com"} target="_blank" className="is-loading" />)
                             : (<button className={`gradiant ${this.state.isloading ? "button is-loading" : ""}`} onClick={this.handleSignup}>{this.state.text}</button>)
        }
        <p className="gray_color">{this.state.message}</p>
        <br />
        <p className="gray_color">After creating account don't forget to :</p>
        <ul className="slacStep">
          {
            this.props.slackSteps.map(elm => (
              <li key={elm}>{elm.replace("{channel}",`#${this.props.region.slackchannel ?  this.props.region.slackchannel : "general"}`)}</li>
            ))
          }  
        </ul>
      </div>
    )
  }
}

export default SignupWithSlack
