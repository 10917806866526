import React from 'react'
import './notfound.css'
function NotFound() {
  return (
    <div>
      <p className="title text-flicker-in-glow">404 Not Found</p >
    </div>
  )
}

export default NotFound
