/* eslint-disable */
import React, {Component, Fragment} from 'react'
import Tag from '../Tag/Tag';
import Button from '../Form/Button/Button';
import {connect} from "../../utils/context";
import VideoPoster from '../VideoPoster';
import './steps.css'
import api from '../../utils/api'
import auth from '../../utils/auth';
import FormDialog from '../dialog_';
import backend from '../../utils/backend';
export class Signup extends Component {
  static contextType = connect

  state = {
    registered: false,
    isLoading: false
  }

  handleSubscription = (account) => {
    this.setState({isLoading: true})
    if(account.length > 0 ){
      
      const data = {
        fullname: this.context.user.username,
        username: this.context.user.email,
        github: account,
        challenge: this.context.user.skill,
        city: this.context.user.region.toLowerCase()
      }
      backend
        .signupForChallenge(data.fullname,data.username,account,data.city,data.challenge)
        .then(res => {
          this.setState({isLoading: false, registered: true})
          backend.updateData('registered', true).then(res=> {
            api.saveData('registered', true)
            this.setState({
              registered: true,
              isloading:false
            })
          }).catch(err=> {
            api.saveData('registered', false)
            this.setState({
              err,
              isLoading:false,
              registered: false
            })
            auth.signout(()=> window.location.reload(true))
          })
        })
        .catch(err => {
          this.setState({err, isLoading: false, registered: false});
        })
    }else {
      
    }
  }
  formatDate = () => {
    try {
      // convert to timestamp
      let started = new Date(this.context.user.started)
      let ended = new Date(this.context.user.end)
      // calculate the defrence to days
      let duration = (ended.getTime() - started.getTime()) / 1000 / 60 / 60 / 24
      return Math.floor(duration)
    } catch (err) {
      return 0;
    }
  }
  render() {
    return (
      <Fragment>

      <div className="signup">
        <div style={{
          marginBottom: 10
        }}>
          <Tag tag={this.context.user.level} selection={this.context.user.level}/>
          <Tag tag={this.context.user.skill} selection={this.context.user.skill}/>
        </div>
        <p className="gray_color git10">{this.props.text[0]}</p>
        <p className="su_title">Read the Challenge Guide</p>
        <p className="gray_color git10">
          {this.props.text[1]}
          <a href={this.props.links[1]}>here</a> &#160;
          {this.props.text[3]}
        </p>
        <p className="su_title">Register for your Coding Challenge</p>
        <p className="gray_color git10">{this.props.text[3]}</p>
        <br />
        <Button text="Register for the challenge" style={{width: 250}} link="https://challenge.codedoor.org/" />
        {/* {
          this.context.user.skill === 'Mobile Development' ?
          <p>Please Ask your regional cordinator to help you register!</p>
          :(
            this.context.user.registered || this.state.registered ? <Button
              style={{width: 250}}
              text={`Already Registered`}
              className={this.state.isLoading ? 'button is-loading' : null}
              link="https://challenge.codedoor.org/"
            target="_blank" /> : <FormDialog isloading={this.state.isLoading}  handleSubscription={this.handleSubscription} text="Register for the challenge"  />
          )
        } */}
        <br />
        </div>
        <div>
          <p className="su_title">
            in case you have difficults solving the Challenge
          </p>
          <p className="gray_color git10">
            {this.props.text[4]}
          </p>
        </div>
        <div>
          <p className="su_title ">
            in case you have difficults using GitHub
          </p>
          <ul>
            <li>
              <a href={this.props.links[0]}>Read the FAQ</a>
            </li>
            <li>Ask for help at the Slack channel<a href="https://codedoor.slack.com/messages/CE11WE686/">
                <strong>#git-clinic</strong>
              </a>
            </li>
            <li>Have a look at videos</li>
          </ul>
        </div>
        <ul
          style={{
            display: "flex",
            padding: 15,
            flexWrap: "wrap",
            listStyleImage: "none"
          }}>
          <li className="no-point">
            <VideoPoster
              link="https://eu.udacity.com/course/how-to-use-git-and-github--ud775"
              img="https://cdn.lynda.com/course/614319/614319-636382993047369499-16x9.jpg"/>
          </li>
          <li className="no-point">
            <VideoPoster
              link="https://www.codecademy.com/learn/learn-git"
              img="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Git_session.svg/1200px-Git_session.svg.png"/>
          </li>
        </ul>
    </Fragment>
    )
  }
}

export default Signup
