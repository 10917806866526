import React, {Fragment} from 'react'
import './tag.css'
import api from '../../utils/api';
import {connect} from '../../utils/context';
import Tag from './Tag'
import backend from '../../utils/backend';
import auth from '../../utils/auth';
class TagButton extends React.Component {
  static contextType = connect

  state = {
    level: this.props.level || "Beginner",
    err:""
  }

  handleClick = (tag) => {
    api.storeKnowledge("level", tag)
    this.setState({level: tag})
    backend.updateData('level', tag).then(res=>{
      this.context.updateLevelandSkill()
      }).catch(err => {
        this.setState({
          err:err
        })
        auth.signout(()=> window.location.reload(true))
      })

  }
  componentDidMount() {
    if (typeof this.props.tags === 'undefined') {
      window
        .localStorage
        .clear()
      window
        .location
        .reload(true)
    }
  }

  render() {
    return (
      <Fragment>
        <div className="tags has-addons has-pointer">
          {this
            .props
            .tags
            .map(tagitem => (<Tag key={tagitem} handleClick={this.handleClick} onClick={true} selection={this.state.level} tag={tagitem}/>))}
        </div>
      </Fragment>
    )
  }
}

export default TagButton
