import React, {Fragment} from 'react';
import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
import {Avatar} from '@material-ui/core';
import './steps.css';

export default function JoinTheClass(props) {
  const lat = props.details.cordination
    ? parseFloat(props.details.cordination.lat || 0)
    : 0.0000
  const lng = props.details.cordination
    ? parseFloat(props.details.cordination.lon || 0)
    : 0.0000
  return (
    <div>
    {props.details.region === "Remote" 
    ? <div className="gray_color p10">
    <p className="gray_color">Please join the <strong>#internat-scholarship</strong> channel on slack and look for a study buddy by posting your name & country flag & coding interest.</p>
    </div>
    : <Fragment>
        {props.details.cordination
          ? (
            <Fragment>
              {props.details.address && (
                <div className="gray_color p10">
                  {
                    props.details.day && props.details.time.start && props.details.time.end ? (
                      <p>We meet every {props.details.day}&#160; from {props.details.time.start}&#160; to {props.details.time.end}&#160; you find us at:</p>
                    ): null
                  }
                  <p>{props.details.address}
                  </p>
                  <ul className="notesul">
                    {props
                      .cordinator
                      .notes
                      .map(elm => (
                        <li key={elm} className="notes">{elm}</li>
                      ))}
                  </ul>
                </div>
              )}
              {(lat && lng) === 0
                ? null
                : (
                  <div className="map">
                    <Map center={[lat, lng]} zoom={18} width={600} height={400}>
                      <Marker
                        anchor={[lat, lng]}
                        payload={1}
                        onClick={({event, anchor, payload}) => {}}/>
                    </Map>
                  </div>

                )}
            </Fragment>
          )
          : (
            <Fragment>

              <p className="gray_color p10">Please Contact Your Regional Cordiator</p>
              <div className="gray_color p10">
                <p
                  style={{
                  textDecoration: "underline",
                  color: "#333"
                }}>{props.cordinator.email
                    ? props.cordinator.email
                    : null}</p>
                <p className="gray_color">{props.cordinator.signiture
                    ? props.cordinator.signiture
                    : null}</p>
                <p>
                  <strong>{props.cordinator.name
                      ? props.cordinator.name
                      : null}</strong>
                </p>
                <Avatar
                  alt="cordinator avatar"
                  src={props.cordinator.avatar
                  ? props.cordinator.avatar
                  : null}
                  className="avatar"/>
              </div>

            </Fragment>
          )
}

      </Fragment>}
    </div>
  )
}
