/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {connect} from '../../utils/context'
import api from '../../utils/api';
import "./steppers.css"
import {region} from '../../utils/data'
import WelcomeToCodedoor from '../steps/WelcomeToCodedoor';
import JustTagYourWay from '../steps/JustTagYourWay';
import SignupWithSlack from '../steps/SignupWithSlack';
import JoinTheClass from '../steps/JoinTheClass';
import AddGithubAccount from '../steps/AddGithubAccount'
import MatchingPlatform from '../steps/MatchingPlatform';
import Learn from "../steps/Learn.js"
import Signup from '../steps/Signup';
import backend from '../../utils/backend';
import auth from '../../utils/auth';
const styles = theme => ({
  root: {
    width: '100%',
    color: "blue",
    minWidth:"200px"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  icon: {

    color: "#00afea"
  },
  activeIcon: {
    color: "#00afea !important"
  }
});

// 00afea

class VerticalLinearStepper extends React.Component {
  getSteps = () => {
    return api.steps
      .sort((a,b)=> a.step - b.step)
      .map(elm => elm.text)
  }
  getStepContent = (step) => {
    try {
      let data = this
      .state
      .events
      .find(elm => parseInt(elm.step) === parseInt(step))
        switch(data.step) {
          case 0:
          return <WelcomeToCodedoor
                  content={[...data[this.state.user.region.toLowerCase()]]}
                  link={this.state.region_data.calendy}
                  btnText={this.state.region_data.calendly_text}
                  email={this.state.region_data.email}
                  signiture=""
                  cordinator={this.state.region_data.name}
                  avatar={this.state.region_data.avatar}/>
          case 1:
            return <JustTagYourWay leveltitle="Knowledge level"  skilltitle="Area of interest" title={data.content} skills={data.skills} levels={data.levels} />
          case 2:
            return <SignupWithSlack slackSteps={data.content} region={this.state.region_data} />
          case 3:
            return <JoinTheClass details={this.state.region_data} cordinator={this.state.region_data}/> 
          case 4:
            return <AddGithubAccount text={data.content}  />
          case 5:
            return <MatchingPlatform text={data.content} faq={data.faq}/>
          case 6:
            return <Learn text={data.content} links={data.links} codes={data.codes}/>
          case 7:
            return <Signup text={data.content} links={data.links} />
          default:
            return <h1>Welcome</h1>
        }

      
    } catch (e) {
      return "no Content added"
    }

  }
  static contextType = connect
  state = {
    ...this.context,
    activeStep: this.context.currentTimeline,
    mode: "vertical",
    region_data: this.context.regions.find(elm => elm.region === this.context.user.region)
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
    api.updateCurentItem(this.state.activeStep + 1);

  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
    api.updateCurentItem(this.state.activeStep - 1);

  };
  handleClick = (step) => {
    this.setState({activeStep: step})
    api.updateCurentItem(step);

  }

  handleReset = () => {
    // this.setState({activeStep: 0})
    // api.updateCurentItem(0);
    this.props.finished()
  };

  render() {
    const {classes} = this.props;
    const steps = this.getSteps();
    const {activeStep} = this.state;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} style={{width:"100%"}} orientation={this.state.mode}>
          {steps.map((label, index) => (
            <Step key={index} >
              <StepLabel
                onClick={() => this.handleClick(index)}
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active:classes.activeIcon,
                    completed:classes.activeIcon
                  }
                }}><strong style={{cursor: "pointer ",}} >{label}</strong></StepLabel>
              <StepContent>
                <div>{this.getStepContent(index)}</div>
                <div className={classes.actionsContainer}>
                  <div>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className="deyanemo">
                      {activeStep === steps.length - 1
                        ? 'Finish'
                        : 'COMPLETE THIS TASK'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed! </Typography>
            {/* <Typography>All steps completed - you&apos;re finished</Typography>
            <Button className="deyanemo" style={{color:"#fff"}} onClick={this.handleReset} >
              Submit Your Request
            </Button> */}
          </Paper>
        )}
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(VerticalLinearStepper);
