/* eslint-disable */
import React, {Component} from 'react'
import './steps.css'
import {connect} from '../../utils/context';
import TagButton from '../Tag';
import SkillTag from '../Tag/SkillTag';

export class JustTagYourWay extends Component {
  static contextType = connect;
  render() {
    return (
      <div className="containers">
        <p className="heading-text"><strong>{this.props.title}</strong></p>
        <p className="knowledge_text">{this.props.leveltitle}: </p>{
              <TagButton level={this.context.user.level} mode="level" tags={this.props.levels}  />
        }
        <p className="knowledge_text">{this.props.skilltitle}: </p>{
              <SkillTag skill={this.context.user.skill} tags={this.props.skills} />
        }
      </div>
    )
  }
}

export default JustTagYourWay
