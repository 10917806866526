/* eslint-disable */
import React from 'react'
import 'bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css'
import './timeline.css'
import API from '../../utils/api';
import {connect} from '../../utils/context'
import StepperVerticalLinearStepper from '../Steppers'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import backend from '../../utils/backend';


class TimeLine extends React.Component {
  static contextType = connect;
  changeContent = (id) => {
    let itmes = this.state.events
    let item = itmes.find(elm => elm.id === id)
    item.status = true;
    this.setState({
      ...itmes,
      currentTimeline:item.step + 1
    })
    this.context.moveStep(item.step)
    API.updateCurentItem(item.step)
    API.saveSteps(itmes)
  }

  handelFinish = () => {
    backend.updateData('finished',true).then(res => {
      API.saveData('finished',true)
      window.location.reload(true)
    }).catch(err=>{
      API.saveData('finished',false)
      auth.signout(()=> window.location.reload(true))
    })
  }

  formatDate = () => {
    try {
      // convert to timestamp
      let started = new Date(this.context.user.started)
      let ended = new Date(this.context.user.end)
      // calculate the defrence to days
      let duration = (ended.getTime() - started.getTime()) / 1000 / 60 / 60 / 24
      return Math.floor(duration)
    } catch (err) {
      return 0;
    }
  }
    state = {
      events:this.context.events,
      currentTimeline:this.context.currentTimeline,
      finished: this.context.user.finished
    }
      render() {
      return (
        <React.Fragment>
      <div className="timeline centercontent">
          <div>
            {
              this.state.finished 
              ? <div style={{position:"relative", width:"100%"}}>
                <Paper style={{padding:40}} elevation={1}>
                  <Typography variant="h4" component="h3">
                    Well Done  👏
                  </Typography>
                  <Typography component="h5" variant="h5">
                    Your Project due in {this.formatDate()} day(s) 😲 
                  </Typography>
                  
                </Paper>
                </div>
              : <StepperVerticalLinearStepper finished={this.handelFinish} />

            }
          </div>
          </div>
      </React.Fragment>
    )
  }
}

export default TimeLine