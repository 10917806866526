import React, {Component} from 'react'
import Button from '../Form/Button/Button';
import VideoPoster from '../VideoPoster';
import { connect } from '../../utils/context';
import backend from '../../utils/backend';
import api from '../../utils/api';
import auth from '../../utils/auth';

export class MatchingPlatform extends Component {
  static contextType = connect;
  state  = {
    platform: this.context.user.platform
  }
      handelSignin = () => {
        backend.updateData("platform", true).then(res => {
          api.saveData("platform",true)
          this.setState({
            platform: true,
          })
        }).catch(err => {
          api.saveData("platform",false)
          this.setState({
            platform: false
          })
          auth.signout(()=> window.location.reload(true))
        })
      }
  render() {
      const video = this.props.faq
      const steps = this.props.text.filter(elm => elm.match(/^-/ ));
    return (
      <div>
        <p className="gray_color git10">{this.props.text[0]}</p>
        {
          this.state.platform ? <Button  text={"Registered"} /> : <Button onClick={this.handelSignin} text={"Create an account"} link="https://codedoor.netlify.com" target="_blank" />


        }
        <p className="gray_color git10" style={{fontSize:"1rem"}}>{this.props.text[1]}</p>
        <ul>
            {
             steps.map(elm => (
                <li className="gray_color" key={elm}>{elm.replace("- ",'')}</li>
              ))
            }
          </ul>
        <p className="gray_color p2">if you need help signing in, watch this video</p>
        <VideoPoster link={video} img={"https://i.imgur.com/kmw7193.png"} />
      </div>
    )
  }
}

export default MatchingPlatform
