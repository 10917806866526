import Axios from "axios";
import backend from "./backend";
import auth from "./auth";


class API {
    
    constructor(storage) {
        this.name = "Steps"
        this.currentStep = JSON.parse(storage.getItem('current')) || 0
        this.storage = storage
        this.cc = "https://suspicious-tesla-fe39ac.netlify.com/success/"
    }
    
    get steps() {
        const steps = this.storage.getItem(this.name)
        return JSON.parse(steps) || []
    }
    saveRegions(regions) {
        this.storage.setItem("regions",JSON.stringify(regions));
    }
    get getRegions() {
        const regions = JSON.parse(this.storage.getItem("regions"))
        return regions
    }

    get current(){
        return parseInt(this.currentStep)
    }
    get user() {
        return JSON.parse(window.localStorage.getItem('auth')) || {}
    }

    toggle(stepId) {
        let data = JSON.parse(this.storage.getItem(this.name))
        let item = data.find(elm => elm.id === stepId)
    }
    addSteps(steps){
        this.storage.setItem(this.name,JSON.stringify(steps));
    }
    generateId() {
        return '_' + Math.random().toString(36).substr(2,9);
    }
    updateCurentItem(count) {
        backend.updateData("activeStep", count).then(res=> {
            this.storage.setItem('current',count);
        }).catch(err => {
            this.storage.setItem('current',count);
        })
    }
    saveSteps(steps) {
        this.storage.setItem(this.name,JSON.stringify(steps))
    }
    storeKnowledge(key,data) {
        let saved = JSON.parse(this.storage.getItem("auth")) || {}
        saved[key] = data
        this.storage.setItem("auth", JSON.stringify(saved))                                                                                                                                                                                                                                                                                             
    }

    applyToCodeChallenge(user) {
        return Axios.post("http://127.0.0.1:5000/cc/register",user)
    }

    saveData(key, value) {
        let user = this.user;
        user[key] = value;
        this.storage.setItem('auth', JSON.stringify(user));
    }

    get getNotification() {
        const noti = JSON.parse(this.storage.getItem("auth"))
        return noti.notifications
    }
    getEventsfromExternalAPI() {
        return backend.getSteps().then(res => {
            return res
        }).catch(err => {
            return []
        })
    }
    getRegionsfromExternalAPI() {
        return backend.getRegions().then(res => res).catch(err => [])
    }
 }

export default new API(window.localStorage);