import Axios from "axios";
import auth from "./auth";

class Backend {
    constructor(url) {
        this.url = url
    }
    fetchNotification() {
        const url = `${this.url}/api/v1/users/notify/${auth.auth._id}`
        return Axios.get(url, {
            headers:{
                "Authorization": `Bearer ${auth.auth.token}`
            } })
    }
    getSteps() {
        const url = `${this.url}/api/v1/users/steps`
        return Axios.get(url);
    }
    getRegions() {

          const url = `${this.url}/api/v1/users/regions`
        return Axios.get(url);
    }
    signIn(user){
        const url = `${this.url}/api/v1/users/create`
        return Axios.post(url, {
            ...user
        })
    }
    storeActiveStep(count) {
        const url = `${this.url}/api/v1/users/update`
        return Axios.patch(url,{
            activeStep: count
        }, {
            headers: {
                "Authorization": `Bearer ${auth.auth.token}`
            }
        })
    }
    updateData(key,value) {
        const url = `${this.url}/api/v1/users/update`;
        return Axios.patch(url, {
            key,
            value
        }, {
            headers:{
                "Authorization": `Bearer ${auth.auth.token}`
            }
        })
    }
    signupForChallenge(fullname, username,github,city,challenge) {
        return Axios.post('http://localhost:5000/cc/register',{
            fullname,
            username,
            github,
            city,
            challenge
        })
    }

    loginToPage(user) {
        const url = `${this.url}/api/v1/users/login`
        return Axios.post(url, {
            ...user
        })
    }

    signupForSlack() {
        return Axios.post('https://cdccapi.herokuapp.com/cc/slack',{
            email: auth.auth.email,
        })
    }
}

export default new Backend("https://cdjourney.herokuapp.com")
// export default new Backend("http://localhost:3001")
// https://cdjourney.herokuapp.com