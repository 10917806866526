import React from 'react'
import {Route ,Redirect} from 'react-router-dom'
import auth from '../utils/auth'


export default class Private extends React.Component {
  render() {
    const { Component} = this.props
    return (
        <Route
          {...this.props}
          render={props =>
            auth.status ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
  }
}
