import React, {Component} from 'react'
import {connect} from '../../utils/context';
import './steps.css'
import { Avatar } from '@material-ui/core';


class WelcomeToCodedoor extends Component {

state = {
  content: []
}
componentDidMount() {
  this.setState({
    content:this.props.content[0]
  })
}

  createMarkup = (data) => {
    return {__html: data}
  }

  static contextType = connect;


  renderContent = () => {
    try {
    return <div className="gray_color"
         dangerouslySetInnerHTML={this.createMarkup(this.state.content
         .replace("{username}", this.context.user.username)
         .replace("{cordinator}", this.props.cordinator)
         .replace("{region}", this.context.user.region)
         .replace("{calendrybtn}", `<div>
         <a href=${this.props.link}>
         <button class="gradiant">${this.props.btnText}</button>
       </a></div>`)
         )
         }>
        </div>
    }
    catch {
      return <div />
    }
  }
  render() {


    return (
      <div>

        {this.renderContent()}

        <p  style={{
          textDecoration: "underline",
          color:"#333",
        }}>{this.props.email
            ? this.props.email
            : null}</p>
        <p className="gray_color">{this.props.signiture
            ? this.props.signiture
            : null}</p>
        <p>
          <strong>{this.props.cordinator
              ? this.props.cordinator
              : null}</strong>
        </p>
        <Avatar alt="cordinator avatar" src={this.props.avatar
          ? this.props.avatar
          : null} className="avatar" />
      </div>
    )
  }
}

export default WelcomeToCodedoor