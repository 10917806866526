import React from 'react';
import Button1 from '../Form/Button/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';

export default class FormDialog extends React.Component {
  state = {
    open: false,
    account:"",
    isloading: this.props.isloading
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, isloading:false });
  };
  handleSubscripe = () => {
      this.setState({
          open: false,        })
    this.props.handleSubscription(this.state.account)
    }

  render() {
    return (
      <div>
        <Button1 className={`${this.state.isloading ? "gradiant button is-loading" : ""}`} style={{
            width: 250
          }} text={this.props.text} color="primary" onClick={this.handleClickOpen}>
          Open form dialog
        </Button1>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your GitHub Username We will send
              updates occasionally.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="GitHub Account Name"
              value={this.state.account}
              onChange={(e) => this.setState({
                  account:e.target.value
              })}
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={(e) => this.handleSubscripe(e)} color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}