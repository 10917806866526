import React, {Component} from "react";
import "./App.css";
import Header from "./components/header/Header";
import HeroText from "./components/HeroText/HeroText";
import TimeLine from './components/timeline'
class Dashboard extends Component {
  render() {
    return (
      <div>
        <Header/>
        <HeroText
          headingText="Your CodeDoor Journey"
          subtext1="This is where you can track your progress, share your results with your"
          subtext2="regional coordinator and check what’s on your plate."
          margin="150px"
          />
          <TimeLine />
      </div>
    );
  }
}

export default Dashboard;
