import React, { Fragment } from 'react'
import './tag.css'
import api from '../../utils/api';
import { connect } from '../../utils/context';
import backend from '../../utils/backend';
import auth from '../../utils/auth';

class SkillTag extends React.Component {

static contextType = connect
  
state = {
    skill: this.props.skill || "Front-End"
  }


  handleClick = (tag) =>{
     this.setState({
      skill:tag
      })
      backend.updateData('skill', tag).then(res=>{
        this.context.updateLevelandSkill()
        api.storeKnowledge("skill", tag)
        }).catch(err => {
          this.setState({
            err:err
          })
          auth.signout(()=> window.location.reload(true))
        })
  }
  componentDidMount() {
      // if(typeof this.props.tags ==='undefined'){
      //     window.localStorage.clear()
      //     window.location.reload(true)
      // }
  }


  render() {
      const {tags} = this.props || []
    return (
      <Fragment>
        <div className="tags has-addons has-pointer">
        {tags.map(tag => (
          <Fragment key={tag}>
              <label 
              onClick={() => this.handleClick(tag)}
              htmlFor="data">
                <span className={`tag is-rounded ${this.state.skill === tag ? "is-taged" : "is-not-taged"}`}>{tag} {this.state.skill === tag
                  ? <a className="custom_tag">
                      <i className="fas fa-check"></i>
                    </a>
                  : <a className="custom_tag">
                    <i className="fas fa-plus"></i>
                  </a>}</span>
              <input className="input" style={{display:"none"}} name="data" type="radio" />
                </label>
              </Fragment>
          ))}
          </div>
      </Fragment>
        )
      }
}

export default SkillTag
