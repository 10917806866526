import React from 'react';
import {connect} from '../../utils/context';
import {withRouter} from 'react-router-dom'
import {Link} from 'react-router-dom';
import './notify.css'
import api from '../../utils/api';
import auth from '../../utils/auth';

class NotifyMenu extends React.Component {
  static contextType = connect
  state = {
    notifications: this.props.notifications
  };

  handleClick = event => {
    var dropdown = document.querySelector('.dropdown');
      dropdown
        .classList
        .toggle('is-active');
  };

  handleClose = () => {
    this.setState({anchorEl: null});
    this
      .props
      .history
      .push('/profile')
  };
  render() {
    return (
      <div>
        <div className="dropdown is-right">
          <div className="dropdown-trigger">
            <div
              onClick={this.handleClick}
              aria-haspopup="true"
              aria-controls="dropdown-menu2"
              className="avatar"
              style={{
              backgroundImage: `url(${this.context.user.avatar})`
            }}></div>
          </div>
          <div className="dropdown-menu" id="dropdown-menu2">
            <div className="dropdown-content">
            <div className="dropdown-item">
            <img src={this.context.user.avatar}  alt={this.context.user.username}/>
            <p style={{
              fontWeight:"bold",
              fontSize:"1.3rem",
              textAlign:"center",

            }}>{this.context.user.username}</p>
            <hr className="dropdown-divider"/>
            </div>
              <div className="dropdown-item">
                <Link
                  to="/profile"
                  style={{
                  color: "#333",
                  padding: 0
                }}>Profile</Link>
              </div>
              <hr className="dropdown-divider"/>
              <div className="dropdown-item">
                <Link
                  to="/notifications"
                  style={{
                  color: "#333",
                  padding: 0
                }}>Notifications</Link>
                <span className="custom-badge">{this.props.notifications.length}</span>
              </div>
              <hr className="dropdown-divider"/>
              <div className="dropdown-item">
                <a style={{
                  color: "#333",
                  padding: 0
                }} onClick={() => auth.signout(() => this.props.history.push('/'))}>Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NotifyMenu);