import React, {Component, Fragment} from 'react'
import './userprofile.css'
import {withRouter} from 'react-router-dom'
import auth from '../../utils/auth';
import Notifications from '../notification'

class Userprofile extends Component {
  logOut = () => {
    auth.signout(() => this.props.history.push('/login'))
  }
  render() {
    return (
      <Fragment>
          <Notifications />
      </Fragment>
    )
  }
}

export default withRouter(Userprofile)