import React, {Component, Fragment} from 'react'
import { connect } from '../../utils/context';
import Header from '../header/Header';
import api from '../../utils/api';
import {distanceInWords} from 'date-fns'
import backend from '../../utils/backend';
import Footer from '../Footer'
import auth from '../../utils/auth';

export class ShowNotify extends Component {
  static contextType = connect;

    state = {
        notifications: this.context.user.notifications,
        notificationInterval_1: null
    }


    createMarkup = (data) => {
        return {__html: data};
      }

      handelRead= (notification) => {
        let nt =this.state.notifications

        nt.find(el => {
          if(el.id ===  notification.id) {
            el.isNew = false
          }
        });

        this.setState({
          notification: nt
        })
        api.saveData('notifications', nt);
        backend.updateData('notifications',nt).then(res => {
        }).catch(e => {
          auth.signout(()=> window.location.reload(true))
        })
      }


      componentDidMount() {
        backend.fetchNotification().then(res => {
          this.setState({
            notifications: res.data.notification
          })
        }).catch(err => {
          this.setState({
            notifications: []
          })
        })
        const notificationInterval_1 = setInterval(() => {
          backend.fetchNotification().then(res => {
            this.setState({
              notifications: res.data.notification
            })
          }).catch(err => {
            this.setState({
              notifications: []
            })
          })
        }, 10000);

        this.setState({
          notificationInterval_1
        })
      }
      componentWillUnmount() {
        clearInterval(this.state.notificationInterval_1)
      }
      
  render() {
    return (
      <Fragment>
          <Header />
          <div className="timeline centercontent">
            <div style={{position: "absolute" ,width: "50%", top: 500 , textAlign: "center"}}>
          {
              this.state.notifications.length > 0 ? this.state.notifications.map(notification => (
                <div key={notification.date} className={`notification ${notification.isNew ? 'is-warning' : 'is-block'}`} style={{width:"400px !important"}}>
                <p>{notification.title}</p>
                <p>{distanceInWords(new Date(notification.date), new Date(Date.now()))}</p>
                {notification.isNew ? <button className="delete" onClick={()=>this.handelRead(notification)}></button> : <button className="delete"></button>}
                <div dangerouslySetInnerHTML={this.createMarkup(notification.contents)}></div>
              </div>
              )) : <div><p>No new notifications</p><span role="img" style={{fontSize:100}} aria-label="sadFace">😔</span></div>
          }
          </div>
          </div>
      </Fragment>
    )
  }
}

export default ShowNotify
