import React, {Component} from 'react'
import Header from '../header/Header';
import {connect} from '../../utils/context';
import {withRouter} from 'react-router-dom'
import 'bulma/css/bulma.css';

export class Profile extends Component {
  static contextType = connect
  render() {
    return (
      <div style={{
        backgroundColor: "#ffffff"
      }}>
        <Header/>
        <div className="timeline profile centercontent">
            <img
              className="profile-img "
              src={this.context.user.avatar}
              alt={this.context.user.username}/>
            <p className="profile-title">{this.context.user.username}</p>
            <div>
              {this.context.user.email}
            </div>

        </div>
      </div>
    )
  }
}

export default withRouter(Profile)
