import React, {Component} from 'react'
import Button from '../Form/Button/Button';
import {connect} from '../../utils/context';
import Tag from '../Tag/Tag';
import './steps.css'
export class MatchingPlatform extends Component {

  constructor(props) {
    super(props)
    this.today = new Date(Date.now())
    const day = this.today.getDate() > 10 ? this.today.getDate() : `0${this.today.getDate()}`
    const year = this.today.getFullYear()
    const month = this.today.getMonth() +1  > 10 ? this.today.getMonth() + 1  : `0${this.today.getMonth() + 1}`
    this.mindate = `${year}-${month}-${day}`
    this.maxdate = `${year}-${this.checkDate(month)}-${day}`
    this.state = {
      date: `${year}-${month}-${day}`
    }
  }
  
  checkDate = (time) => {
    if(parseInt(time) + 2 >= 10) return `${parseInt(time) + 2}`
    if(parseInt(time) + 2 < 10) return `0${parseInt(time) + 2}`
  }

 

  setThedate = (date) => {
    this.setState({date})
  }
  static contextType = connect
  render() {
    return (
      <div>
        <div style={{
          marginBottom: 10
        }}>
          <Tag tag={this.context.user.level} selection={this.context.user.level}/>
          <Tag tag={this.context.user.skill} selection={this.context.user.skill}/>
        </div>
        <p className="gray_color git10">{this.props.text[0]}</p>
        <ul className="slacStep">
          <li>With the code <strong>{this.props.codes.frontend}</strong> you enter the front-end class.</li>
          <li>The code <strong>{this.props.codes.fullstack}</strong> access to the full-stack class.</li>
          <li>With the code <strong>{this.props.codes.dsml}</strong> you open the DS/ML/AI class.</li>
        </ul>
        <p>
        </p>
        <Button
          style={{
          width: 250
        }}
          text={`${this.context.user.skill
          ? `Join the ${this.context.user.skill} Classroom`
          : "Complete Step 2"}`}
          link="https://classroom.google.com"
          target="_blank"/>
        <p className="gray_color git10">
          {this.props.text[1]}
          <a href={`${this.props.links[0]}`}>{this.props.text[2]}</a>
          ,
          <a href={`${this.props.links[1]}`}>{this.props.text[3]}</a>, or with
          <a href={`${this.props.links[2]}`}>
            {this.props.text[4]}</a>
          <span>
            {this.props.text[5]}</span>
        </p>
        <p style={{
          marginTop: 10
        }}>
          <span className="gray_color git10">
            {this.props.text[6]}</span>
          <span className="gray_color git10">
            {this.props.text[7]}</span>
          <span className="gray_color git10">
            {this.props.text[8]}</span>
        </p>
      </div>
    )
  }
}

export default MatchingPlatform
