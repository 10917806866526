import React, {Component} from 'react'

export class AddGithubAccount extends Component {
  render() {
    const steps = this.props.text.filter(elm => elm.match(/^-/ ));
    return (
      <div>
          <p className="gray_color git10">{this.props.text[0]}</p>
          <p className="p10 gray_color">You can create an account <a href="https://github.com/join" rel="noopener noreferrer" target="_blank">here</a></p>
          <p className="gray_color">{this.props.text[1]}</p>
          <ul>
            {
              steps.map(elm => (
                <li className="gray_color" key={elm}>{elm.replace("- ",'')}</li>
              ))
            }
          </ul>
      </div>
    )
  }
}

export default AddGithubAccount
