import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from '../../utils/context';

class ResponsiveDialog extends React.Component {
    static contextType = connect
  state = {
    open: false,
    agreement: this.props.agree
  };

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };
  handelAgree = () => {
      this.setState({
          agreement: true
      })
      this.handleClose()
      this.props.handleTerms(true)
  }
  render() {
    const {fullScreen} = this.props;

    return (
      <div>
        <label className="checkbox">
          <input type="checkbox" checked={this.state.agreement} onChange={this.handelAgree } name="term"/>
        </label>
          <span>&nbsp; I agree to the &nbsp; 
            <a onClick={this.handleClickOpen}>terms and conditions</a>
          </span>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title">
          <DialogTitle id="responsive-dialog-title">{"CodeDoor - Student Agreement"}</DialogTitle>
          <DialogContent>
            <Fragment>
              <p className="term">
                This Agreement forms the basis of the relationship between you and CodeDoor.
                Through your registration you will be subject to the terms of the Student
                Agreement.
              </p>
              <p className="term">
                When you join CodeDoor you give us the right to hold and process your personal
                data including sensitive personal data. You can expect us to take reasonable
                care to keep your personal details secure at all times.
              </p>
              <p className="term">
                By signing this agreement you grant us permission to take pictures of you and
                use them for promoting CodeDoor. If you do not want your pictures to be taken or
                used in public, please notify us immediately.
              </p>
              <p className="term dark">
                I. CodeDoor offers:
              </p>
              <p >
                Free online training in programming and digital problem-solving skills: In
                addition, we provide:
              </p>
              <ul>
                <li>Online tutoring and learning support appropriate to your programme of study
                </li>
                <li>Weekly meetings with technical instructions, offered by professional programmers</li>
                <li>If needed and available formatted hardware (a laptop) and software needed to
                  develop</li>
                <li>Supervised access to co-working or corporate spaces for practice coding and
                  study sessions</li>
                <li>Linking you with companies that match your profile and interest.
                </li>
                <li>If you are hired by the company on the basis of our brokerage, we aim that
                  you will receive a signing fee. We cannot promise this.
                </li>
              </ul>
              <p className="term">
                CodeDoor is a non-profit organization and cannot be held responsible if the
                above-mentioned resources cannot be delivered.
              </p>
              <p className="term dark">II. CodeDoor responsibilities (students):</p>
              <p>As a student working with CodeDoor, you agree to adhere to the following
                commitments:</p>
              <ul>
                <li>Do your best to successfully complete your CodeDoor qualification</li>
                <li>Become an active part of the CodeDoor network</li>
                <li>ttend and engage in weekly study sessions at least twice per month. (Please
                  notify us via Slack if you attend or not.)</li>
                <li>Supervised access to co-working or corporate spaces for practice coding and
                  study sessions</li>
                <li>Take reasonable security measures with CodeDoor property
                  (password-protection of hardware)</li>
                <li>Caution about leaving CodeDoor laptops in unsupervised spaces where they may
                  be lost or stolen
                </li>
                <li>Responsible use of CodeDoor laptops (no prohibited downloads like unlicensed
                  movies or music / no file sharing/streaming services)</li>
                <li>Ensure that CodeDoor property is not taken outside of Germany (country of issue)</li>
                <li>Agree to return CodeDoor laptops in working order once you have completed
                  the course</li>
                <li>After successful completion of the CodeDoor qualification, you will be
                  available for one year for a maximum of 10 hours per month as a tutor (online or
                  offline) for other students.</li>
              </ul>
              <p className="term dark">
                III. CodeDoor Code of Conduct:
              </p>
              <p className="term">
                The CodeDoor Code of Conduct (referred to as “The Code”) outlines standards to
                maintain an environment conducive to learning and in keeping with the objectives
                of the program.
              </p>
              <p>
                These are as follows:
              </p>
              <ul>
                <li>CodeDoor commits to provide an environment where the rights, safety, dignity
                  and worth of every individual are respected.
                </li>
                <li>The CodeDoor community is made up of students and volunteers united by their
                  participation in, and commitment to, respectful intellectual exchange.</li>
                <li>Intellectual exchange implies acting responsibly in a community setting,
                  with respect for the property, common resources and laws associated with that
                  community.
                </li>
                <li>CodeDoor may impose disciplinary measures against students or volunteers
                  whose conduct is objectionable and/or interferes with the educational objectives
                  of CodeDoor or another member of the community.
                </li>
              </ul>
              <br/>
              <p className="term">
                What might be objectionable conduct?
              </p>
              <p className="term">
                Objectionable conduct is defined as Harassment, Unwanted Contact, or otherwise
                objectionable behaviour.
              </p>
              <p>This includes:</p>
              <ul>
                <li>Intentionally subjecting a person to offensive physical contact.</li>
                <li>Insults, gestures, or abusive words, in the immediate present, and/or
                  directed to another person that may reasonably cause emotional distress or
                  provoke a violent response (including but not limited to electronic mail,
                  conventional mail, social media and telephone).</li>
                <li>Prohibited drug use, which includes: sale or possession of a Drug, including
                  but not limited to marijuana or narcotics, during a CodeDoor sponsored activity.</li>
                <li>Lewd or indecent conduct during a CodeDoor sponsored activity. Lewd or
                  indecent conduct includes, but is not limited to, any unauthorized use of
                  electronic or other devices to make an audio or video record that would be an
                  invasion of privacy.</li>
                <li>Other types of prohibited discrimination, discriminatory harassment,
                  nonconsensual personal contact and sexual harassment as defined by law.
                  Nonconsensual personal contact occurs when a person subjects another person to
                  contact of a sexual nature when a reasonable person would know that such contact
                  would cause emotional distress. Sexual harassment includes sexual advances,
                  requests for sexual favors, and other verbal or physical conduct of a sexual
                  nature that interferes with work or study by creating an intimidating, hostile,
                  or degrading environment that deprives a person of benefits of the CodeDoor
                  educational environment.</li>
              </ul>

            </Fragment>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {this.handleClose(); this.setState({agreement: false}) }} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handelAgree} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialog);