import React, {Component, Fragment} from 'react'
import './login.css'
import auth from '../../utils/auth';
import {region} from '../../utils/data'
import {connect} from '../../utils/context';
import {Redirect} from 'react-router-dom'
import 'bulma/css/bulma.css'
import UserAgrement from './UserAgrement';
import backend from '../../utils/backend';
import api from '../../utils/api';
import { Link } from 'react-router-dom';

export default class Login extends Component {
  static contextType = connect;
  state = {
    show: false,
    fullname: "",
    email: "",
    password: "",
    regions:[],
    region: "",
    isValid: false,
    isCaps: false,
    isNum: false,
    fireRedirect: false,
    avatar: "https://www.qualiscare.com/wp-content/uploads/2017/08/default-user.png",
    isAgreed: false,
    err:""
  }

  redirectMe = (path) => {
    this
      .props
      .history
      .push(path)
  }


  getRegions = async () => {
    const regions = await backend.getRegions()
    this.setState({
      regions: regions.data.body
    })
  }



  componentDidMount() {
    this.getRegions();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({err: null})
    const email_pattern = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
    const name_pattern = new RegExp(/[a-zA-Z]/)
    const { isAgreed, email, fullname, region, avatar} = this.state;
    if (email_pattern.test(email) &&  email.length > 4 && region.length > 3) {
      backend.loginToPage({email,region}).then(res => {
        this.context.signin({username: res.data.body.name, email, region, avatar});
          api.updateCurentItem(res.data.body.activeStep)
           auth.authenticate({
            email,
            token:res.data.token,
            username: res.data.body.name,
            region,
            avatar,
            ...res.data.body
          }, () => {})
          return window
          .location
          .reload(true)
        }).catch(err => {
          this.setState({
            err: err.response.data.message
          })
        })
    } else {
      return this.setState({err: "Please Select a region, and check if the email is valid!"})
    }
  }
  handleTerms = (stat) => {
    this.setState({isAgreed: stat})
  }
 
  render() {
    return (
      <Fragment>
        {auth.status
          ? (<Redirect to={'/'}/>)
          : (
            <div className="blue_bg">
              <div className="site-header-inner">
                <div className="brand header-brand">
                  <h1 className="m-0">
                    <a href="#/">
                      <img
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        id="logo-gradient"
                        src="https://i.imgur.com/1BKZMVW.png"
                        alt=""/>
                      <title>CodeDoor</title>
                    </a>
                  </h1>
                </div>
              </div>
              <div className="login_heading_text_container">
                <span className="login_title">Welcome {this.state.fullname + " !"}</span>
                <p className="login_subtitle">CodeDoor is dedicated to enable people to become excellent developers, and our</p>
                <p className="login_subtitle">platform will guide you along the way. Please create an account to see your next</p>
                <p className="login_subtitle">tasks and track your progress</p>
              </div>
              <div className="login_container slide-in-elliptic-bottom-fwd">
                <h3 className="login_heading_text">Login :
                </h3>
                <form onSubmit={this.handleSubmit}>
                  <span>{this.state.err
                      ? this.state.err
                      : null}</span>
                  <div className="field">
                    <label className="login_label">Your email:</label>
                    <p className="control has-icons-left has-icons-right">
                      <input
                        className="input"
                        type="email"
                        value={this.state.email}
                        name="email"
                        onChange={(e) => this.setState({email: e.target.value})}
                        placeholder="Email"/>
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                    </p>
                  </div>

                  <div className="field">
                    <div className="control has-icons-left">
                      <div className="select">
                        <select
                          name="region"
                          defaultValue=""
                          placeholder="Select a Region"
                          onChange={(e) => this.setState({region: e.target.value})}>
                          <option name="region" value="">Select a Region</option>
                          {this.state.regions ? (
                            this.state.regions.map(elm => <option
                              value={elm.region}
                              key={elm.id}>{elm.region}</option>)
                          ) : null}
                        </select>
                      </div>
                      <div className="icon is-small is-left">
                        <i className="fas fa-globe"></i>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <p className="control">
                      <button
                        className="gradiant align_right">
                        Login
                      </button>
                    </p>
                  </div>
                </form>
                <p className="text_grey">if you don't have an account register <Link to="/register">here</Link></p>
              </div>
            </div>
          )}
      </Fragment>
    )
  }
}
