import React, {Component, Fragment} from 'react'
import './header.css'
import Userprofile from '../UserProfile/Userprofile';
import { connect } from '../../utils/context';
import {Link} from 'react-router-dom'

class Header extends Component {
  static contextType = connect
  render() {
    return (
      <Fragment>
        <header className="site-header">
          <div className="container">
            <div className="site-header-inner">
              <div className="brand header-brand">
                <h1 className="m-0">
                  <Link to="/">
                    <img
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      id="logo-gradient"
                      src="https://i.imgur.com/1BKZMVW.png"
                      alt=""/>
                    <title>CodeDoor</title>
                  </Link>
                </h1>
              </div>
              <ul className="header-links list-reset m-0">
                <Userprofile name={this.context.user.username} imageUrl={this.context.user.avatar} />
              </ul>
            </div>
          </div>
        </header>
      </Fragment>

    )
  }
}
export default Header