import React, { Component, Fragment } from 'react'
import backend from '../../utils/backend';
import { Badge, withStyles } from '@material-ui/core';
import NotifyMenu from './NotifyMenu';
import {withRouter} from 'react-router-dom'
import api from '../../utils/api';
import auth from '../../utils/auth';

const styles = theme => ({
    badge: {
      top: '50%',
      right: 0,
      fontSize:14,
      fontWeight:"Bold",
      // The border color match the background color.
      border: `0px solid ${
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`,
    },
  });

class Notifications extends Component {
    state = {
        notifications: [],
        notificationInterval: null
    }
    fetchNotification = () => {
        backend.fetchNotification().then(res => {
          const notif = res.data.notification.filter(elm => elm.isNew === true);
          api.saveData('notifications', notif)
          this.setState({
            notifications:notif,
          })
        }).catch(err => {
          auth.signout(()=> this.props.history.push('/'))
          this.setState({
            notifications:[],
          })
        })
      }
    
      componentDidMount() {
        this.fetchNotification()
        const Interval = setInterval(() => {
          this.fetchNotification()
        }, 30000);
        this.setState({
          notificationInterval :Interval
        })    
      }
      componentWillUnmount() {
        clearInterval(this.state.notificationInterval)
      }
    render() {
        const { classes } = this.props;
    return (
        <Fragment>
                  <Badge badgeContent={parseInt(this.state.notifications.length)} color="primary" classes={{ badge: classes.badge }}>
                        <NotifyMenu notifications={this.state.notifications} />
                  </Badge>
        </Fragment>
    )
  }
}

export default withRouter( withStyles(styles)(Notifications))


