import React from 'react'
import './HeroText.css'

function HeroText(props) {
  return (
    <div className="hero text-light text-center" style={{marginBottom:`${props.margin}`}}>
      <div className="hero-inner">
        <h1
          className="hero-title h2-mobile mt-0 is-revealing slide-in-top"
          style={{
          visibility: "visible",
          fontSize:"2.5rem",
          fontFamily:"sans-serif"
        }}>{props.headingText}</h1>

        <p
          className="hero-paragraph is-revealing scale-in-ver-center"
          style={{
          visibility: "visible"
        }}>{props.subtext1}
          <br/>
          {props.subtext2}
        </p>
      </div>
    </div>
  )
}

export default HeroText