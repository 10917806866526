/* eslint-disable */
import React, {Component} from "react";
import "./App.css";

import {connect} from './utils/context'
import API from './utils/api'
import {Switch, Route} from 'react-router-dom';
import Private from './Routes/Private';
import Login from './components/Login'
import Dashboard from "./Dashboard";
import NotFound from "./components/NotFound";
import backend from "./utils/backend";
import Profile from "./components/Profile";
import ShowNotify from "./components/notification/ShowNotify";
import ReactGA from 'react-ga';
import Register from "./components/Register";


class App extends Component {

  state = {
    events: API.steps,
    currentTimeline: API.current,
    userData: {},
    user: API.user,
    notifications:[],
    regions:API.getRegions
  }

  setNotifications = (notifications) => {
    this.setState({
      notifications
    })
  }
  updateLevelandSkill = () => {
    this.setState({user: API.user})
  }

  moveStep = (step) => {
    this.setState({currentTimeline: step})
  }

  signInUser = (user) => {
    this.setState({
      user,
      ...this.state
    })
  }

  getEvents = (events) => {
    this.setState({events})
  }


  addSkillandLevel = (skill, level) => {
    this.setState({
      userData: {
        [skill]: level
      },
      ...this.state
    })
  }

  componentDidCatch() {
    localStorage.clear()
  }

  fetchNotification = () => {
    backend.fetchNotification().then(res => {
      const notif = res.data.notification.filter(elm => elm.isNew === true);
      api.saveData('notifications', notif)
      this.setState({
        notifications:notif,
      })
    }).catch(err => {
      this.setState({
        notifications:[],
      })
    })
  }


  componentWillUnmount() {
    clearInterval(this.state.notificationInterval)
  }

  componentDidMount() {
    if (window.location.host === "localhost:3000") {
    } else {
      if (window.location.protocol !== "https:") {
        window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      }
    }
    API.getRegionsfromExternalAPI().then(regions => {
      this.getEvents(regions.data.body)
      API.saveRegions(regions.data.body)
    }).catch(err=>{});
    API.getEventsfromExternalAPI().then(events => {
      this.getEvents(events.data.body)
      API.addSteps(events.data.body)

    }).catch(err => {
    })
    if(!window.location.pathname.includes('login')){
      this.fetchNotification()
    }
    const Interval = setInterval(() => {
      if(!window.location.pathname.includes('login')){
        this.fetchNotification()
      }

    }, 30000);
    this.setState({
      notificationInterval :Interval
    })    
    API.generateId();

    this.initializeReactGA()
    
  }

   initializeReactGA = () => {
    ReactGA.initialize('UA-117531428-7');
    ReactGA.pageview('/');
}
  render() {
    return (
      <div>
        <connect.Provider
          value={{
          notifications:this.state.notifications,
          regions:this.state.regions,
          events: this.state.events,
          currentTimeline: this.state.currentTimeline,
          moveStep: this.moveStep,
          userData: this.state.userData,
          user: this.state.user,
          signin: this.signInUser,
          addSkillandLevel: this.addSkillandLevel,
          updateLevelandSkill: this.updateLevelandSkill,
          fetchNotification:this.fetchNotification,
          setNotifications: this.setNotifications
        }}>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Private exact path="/" Component={Dashboard}/>
            <Private exact path="/profile" Component={Profile}/>
            <Private exact path="/notifications" Component={ShowNotify}/>
            <Route component={NotFound}/>
          </Switch>

        </connect.Provider>
      </div>
    );
  }
}

export default App;
