import React, {Component} from 'react'

export class VideoPoster extends Component {
  render() {
    return (
      <div>
        <a href={this.props.link}>
          <div
            style={{
            background: "#333",
            width: 200,
            height: 100,
            padding: 10,
            backgroundImage: `url(${this.props.img})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative"
          }}>
            <i
              style={{
              color: "#fff",
              position: "absolute",
              background: "#333",
              width:50,
              height:50,
              padding: 0,
              marginLeft:3,
              borderRadius: 50,
              opacity: 0.9,
              top: "50%",
              left: "50%",
              fontSize: "2rem",
              textAlign: "center",
              transform: "translate(-50%,-50%)"
            }}
              className="fas fa-play"></i>
          </div>
        </a>
      </div>
    )
  }
}

export default VideoPoster
