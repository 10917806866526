import React , {Fragment} from 'react'

export default function Tag(props) {
    const {handleClick, selection, tag} = props
    const handleTheClick =(tag) => {
        if(props.onClick===true) {
            return handleClick(tag)
        }
        return
    }
  return (
    <Fragment>
      <label onClick={() =>handleTheClick(tag)} htmlFor="data">
        <span
          className={`tag is-rounded ${selection === tag
          ? "is-taged"
          : "is-not-taged"}`}>{tag} {selection === tag
            ? <a className="custom_tag">
                <i className="fas fa-check"></i>
              </a>
            : <a className="custom_tag">
              <i className="fas fa-plus"></i>
            </a>}</span>
        <input
          className="input"
          style={{
          display: "none"
        }}
          name="data"
          type="radio"/>
      </label>
    </Fragment>
  )
}
