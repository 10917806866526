import React from 'react'
import './button.css'
function Button(props) {
  return (
    <a href={props.link} {...props}>
      <button type={props.type} {...props} className={props.className || "gradiant"}>
      {props.text}
      </button>
    </a>
  )
}

export default Button
